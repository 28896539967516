body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* React-Toastify Overrides */
  --toastify-color-success: #2e7d32;
  --toastify-color-info: #0288d1;

  --toastify-color-dark: #9e9e9e;

  --toastify-toast-width: 400px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Toastify__toast {
  cursor: unset !important;
}

.Toastify__toast-container {
  font-size: 12px;
}
